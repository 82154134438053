<template>
  <div class="comments">
    <ul class="list" v-if="history.length">
      <li v-for="(msg, index) in history" :key="index">
        <img
          v-if="msg.target.profile_photo"
          class="avatar"
          :src="formartImageUrl(msg.target.profile_photo)"
          @click="
            routeJump({
              name: 'personalCenter',
              params: { id: msg.target.reviewer_id },
            })
          "
        />
        <i v-else class="avatar el-icon-user-solid"></i>
        <dl class="right">
          <dd>
            <span
              class="name"
              @click="
                routeJump({
                  name: 'personalCenter',
                  params: { id: msg.target.reviewer_id },
                })
              "
              >{{ msg.target.name }}
            </span>
            <span class="time">{{ msg.target.review_datetime }}</span>
          </dd>
          <dd class="content" v-html="msg.target.content"></dd>
          <dd class="replay">
            <i
              class="el-icon--replay btn"
              v-if="!msg.target.replay"
              @click="showReplayBox('history', index)"
            ></i>
            <div class="replay-child-box" v-else>
              <span class="flag">
                <b class="el-icon--replay">
                  {{ $t("replay") + ":" + msg.target.name }}
                </b>
              </span>
              <!-- <div class="innnerInput" contenteditable="true" :ref="'input' + index"></div> -->
              <input class="innnerInput" :ref="'input' + index" value />
              <i
                class="el-icon-check active"
                @click="replayComment(index, msg.target.review_id)"
              ></i>
            </div>
          </dd>
          <ul class="child">
            <li v-for="(child, key) in msg.children" :key="key">
              <p>
                <span class="name">{{ child.name }}</span>
                {{ $t("replay") }}
                <span class="name">{{ child.parent_reply_name }}</span>
                <span v-html="child.content"></span>
              </p>
              <i
                class="el-icon--replay"
                v-if="!child.replay"
                @click="showReplayBox('history', index, key)"
              ></i>
              <div class="replay-child-box" v-else>
                <span class="flag">
                  <b class="el-icon--replay">
                    {{ $t("replay") + ":" + child.name }}
                  </b>
                </span>
                <!-- <div class="innnerInput" contenteditable="true" :ref="'input' + index + '-' + key"></div> -->
                <input class="innnerInput" :ref="'input' + index + '-' + key" />
                <i
                  class="el-icon-check active child"
                  @click="replayComment(index, child.review_id, key)"
                ></i>
              </div>
            </li>
          </ul>
        </dl>
      </li>
    </ul>
    <div class="comment-action">
      <el-input
        type="textarea"
        class="input-dom"
        v-model="content"
        @blur="blurHandler"
        ref="content"
        id="content"
      ></el-input>
      <span
        :class="{ commentBtn: true, active: content !== '' }"
        @click="CommentFunc()"
      >
        <i :class="loading ? 'el-icon-loading' : 'el-icon-check'"></i>
      </span>
    </div>
  </div>
</template>
<script>
import { publicDo } from "@/api/dado";
import { parse } from "path";
import { getReplays, commitContent } from "@/api/rest";
export default {
  name: "Comments",
  data() {
    return {
      url: "public/data.do",
      params: {},
      history: [],
      loading: false,
      content: "",
      hasFouces: false,
      noscroll: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 0,
    },
    fouce: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    watchId() {
      return this.id;
    },
    watchFouce() {
      return this.fouce;
    },
    watchFource() {
      return this.$store.state.webStore.fource;
    },
  },
  watch: {
    watchId(val) {
      this.id = val;
      this.getComments();
    },
    watchFouce(val) {
      // console.log(val, "//val");
      this.hasFouces = val;
      if (val && !this.noscroll) {
        this.scrollDom();
      }
    },
    watchFource: {
      handler(val) {
        if (val) {
          this.noscroll = !val;
          this.scrollDom();
        }
      },
      dreep: true,
    },
  },
  created() {
    this.hasFouces = this.fouce;
  },
  mounted() {
    this.params = {
      start: 0,
      limit: 9999,
      query_no_cache: true,
      table_name_replace: localStorage.getItem("langCode"),
    };
    this.getComments();
    this.$nextTick(() => {
      if (this.hasFouces) {
        this.scrollDom();
      }
    });
  },
  methods: {
    async handlerGetReplays() {
      const result = await getReplays(this.id);
      if (result && result.code === 200) {
        let comments = this.formartComment(result.data.data);
        this.history = this.formartObjToArrary(comments);
        console.log(this.history, comments, "/asdf");
      }
    },
    blurHandler() {
      this.$store.dispatch("commitFource", false);
    },
    scrollDom() {
      if (!this.noscroll) {
        document.documentElement.scrollTop =
          this.$refs.content.$el.offsetTop - 100;
        this.$refs.content.focus();
      }
    },
    showReplayBox(obj, index, leavel) {
      this[obj].map((item, key) => {
        if (index === key) {
          if (leavel) {
            item.children.map((element, el) => {
              if (el === leavel) {
                element.replay = true;
              } else element.replay = false;
              return element;
            });
          } else item.target.replay = true;
        } else {
          if (leavel) {
            item.children.map((element, el) => {
              if (el === leavel) {
                element.replay = true;
              } else element.replay = false;
              return element;
            });
          } else item.target.replay = false;
        }
        return item;
      });
      // if (!leavel) this.history[index].target.replay = true;
    },
    async replayComment(index, id, leavel) {
      if (!this._checkUserLogin()) {
        return;
      }
      let key = "input" + index;
      if (leavel) {
        key += "-" + leavel;
      }

      let content = this.$refs[key][0].value;

      if (!content) {
        this.$message({
          type: "error",
          message: this.$t("replayEmpty"),
        });
        return;
      }
      let replayUrl = "/rest/review/operation/" + this.id;

      let params = {
        operationId: this.id,
        operation: this.type === 0 ? 1 : this.type,
        reply: content, // content
        reviewId: id,
        language: localStorage.getItem("langCode") || "1",
        userId: this.$store.getters.userInfo.id,
      };

      let result = await this.$http({
        url: replayUrl,
        params,
        method: "post",
        withCredentials: false,
      }).catch((e) => {
        console.log(
          e + `from src/components/business/web-site/comments/index.vue`
        );
      });
      if (result) {
        this.getComments();
        this.$refs[key][0].value = "";
        if (leavel) {
          this.history[index].children[leavel].replay = false;
        } else this.history[index].target.replay = false;
      } else
        this.$message({
          type: "error",
          message: this.$t("replayErr"),
        });
      // console.log(result, "////rese");
    },
    _checkUserLogin() {
      // return this.$store.getters.userInfo.id ? true : false;
      // console.log(this.$store.getters.userInfo.id);

      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return false;
      }
      return true;
    },
    async CommentFunc() {
      if (!this._checkUserLogin()) {
        return;
      }
      this.noscroll = true;
      if (!this.content) {
        return;
      }
      this.loading = true;
      let params = {
        content: this.content,
        table_name_replace: localStorage.getItem("langCode") || "1",
      };
      switch (parseInt(this.type)) {
        case 3:
          params.provider_id = this.id;
          params.funcId = "hex_helome_addProviderReviewFunction";
          break;
        case 1:
        case 0:
          params.gig_id = this.id;
          params.funcId = "hex_helome_addHelogigReviewFunction";
          break;
        case 4:
          params.group_id = this.id;
          params.funcId = "hex_helome_addGroupReviewFunction";
          break;
        case 5:
          params.enterprise_id = this.id;
          params.funcId = "hex_helome_addEnterpriseReviewFunction";
          break;
      }
      let result = {};
      if (this.type != 6)
        result = await publicDo(params, this.url).catch((e) => {
          this.$message({
            type: "error",
            message: e.message || "",
          });
        });
      else {
        const user = this.$store.getters.userInfo;
        let newParams = {
          operation: 6,
          reply: this.content,
          language: this.language,
          userId: user.id || "",
        };
        result = await commitContent(newParams, this.id);
      }

      if (
        (result && result.data && result.data.length) ||
        (result && result.code === 200)
      ) {
        this.getComments();
        this.content = "";
        this.$emit("commentSuccessful");
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = !this.loading;
    },
    async getComments() {
      switch (parseInt(this.type)) {
        case 3:
          this.getUserComments();
          break;
        case 1:
        case 0:
          this.getServiceComments();
          break;
        case 4:
          this.getGroupComments();
          break;
        case 5:
          this.getEnterComments();
          break;
        case 6:
          this.handlerGetReplays();
      }
    },
    async getEnterComments() {
      this.params.enterprise_id = this.id;
      this.params.funcId = "hex_helome_queryEnterpriseReviewsByEnterpriseId";
      await this.getAllCommentsFunc();
    },
    async getServiceComments() {
      this.params.gig_id = this.id;
      this.params.funcId = "hex_helome_queryHelogigReviewsByGigId";
      await this.getAllCommentsFunc();
    },
    async getGroupComments() {
      this.params.group_id = this.id;
      this.params.funcId = "hex_helome_queryGroupReviewsByGroupId";
      await this.getAllCommentsFunc();
    },
    async getUserComments() {
      this.params.provider_id = this.id;
      this.params.funcId = "hex_helome_queryProviderReviewsByProviderId";
      await this.getAllCommentsFunc();
    },
    async getAllCommentsFunc() {
      let result = await publicDo(this.params, this.url).catch((e) => {
        console.log(
          e + `from src/components/business/web-site/comments/index.vue`
        );
      });
      if (result) {
        let comments = this.formartComment(result.data);
        this.history = this.formartObjToArrary(comments);
      }
      if (this.hasFouces) this.scrollDom();
    },
    formartObjToArrary(obj) {
      let arr = [];
      Object.keys(obj).forEach((key) => {
        arr.push(obj[key]);
      });
      return arr;
    },
    formartComment(data) {
      var reviews = {};
      let items = data || [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        item.replay = false;
        if (
          (item.parent_review_id &&
            item.parent_review_id != "" &&
            item.parent_review_id != "-1") ||
          (item.parentReviewId &&
            item.parentReviewId != "" &&
            item.parentReviewId != "-1")
        ) {
          item.replay = false;
          if (reviews[item.parent_review_id || item.parentReviewId] == null) {
            reviews[item.parent_review_id || item.parentReviewId] = {
              children: [item],
            };
          } else {
            reviews[
              item.parent_review_id || item.parentReviewId
            ].children.unshift(item);
          }
        } else {
          if (reviews[item.review_id || item.reviewId] == null) {
            reviews[item.review_id || item.reviewId] = {
              target: item,
              children: [],
            };
          } else {
            reviews[item.review_id || item.reviewId].target = item;
          }
        }
      }
      return reviews;
    },
  },
};
</script>
<style lang="stylus" scpoed>
.el-icon--replay {
  color: #999999;
  cursor: pointer;

  &.btn {
    margin: 0.9rem 0;
    padding: 0.3rem 1.5rem;
    border: 1px solid #eeeeee;
    border-radius: 2rem;
  }
}

.commentBtn {
  padding: 0.2rem 2.5rem;
  background: #eeeeee;
  font-size: 1.5rem;
  border-radius: 2rem;
  margin: 0.9rem 0;
  color: #666666;
  cursor: pointer;
}

.innnerInput {
  border: 1px solid #e5e5e5;
  min-height: 40px;
  width: 100%;
  padding: 1.25rem 0.5rem;
  line-height: 28px;
  // text-indent: 0.9rem;
}

.replay-child-box {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: relative;
  margin: 0.5rem 0;

  .flag {
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;

    .el-icon--replay {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
    }
  }

  // justify-content: flex-end;
  i {
    padding: 0 0.9rem;
    background: #eeeeee;
    font-size: 1.5rem;
    border-radius: 2rem;
    margin: 0.9rem;
    color: #666666;
    cursor: pointer;

    &.active {
      color: #ffffff;
      background: #33cc66;
    }
  }
}

.comments {
  padding: 0.9rem;
  display: flex;
  flex-flow: column;
  background: #ffffff;
  border: 1px solid #eeeeee;
  width: 100%;

  .list, .comment-action {
    flex: 1;
    display: flex;
    flex-flow: column;
  }

  .list {
    li {
      margin: 0 0 0.5rem;
      display: flex;
      flex-flow: row;

      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 3rem;
        object-fit: cover;
        border: 1px solid #eeeeee;
        background: #e5e5e5;
        margin-right: 0.9rem;
        text-align: center;
        line-height: 60px;

        &:before {
          font-size: 30px;
          color: #999999;
        }
      }

      dl {
        margin: 0;
        padding: 0;
        flex: 1;
        display: flex;
        flex-flow: column;

        dd {
          margin: 0;
          font-size: 14px;
          color: #333333;
          line-height: 14px;

          &.content {
            margin: 0.5rem 0 0;
            line-height: 20px;
          }

          span {
            &.name {
              margin-right: 0.5rem;
            }

            &.time {
              color: #999999;
              font-size: 12px;
            }
          }
        }

        .child {
          display: flex;
          flex-flow: column;

          li {
            font-size: 14px;
            color: #333333;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            // align-items: center;
            // flex-wrap: wrap;
            position: relative;

            p {
              background: #fafefb;
              border: 1px solid #eeeeee;
              border-radius: 0.2rem;
              display: inline-block;
              width: 100%;
              padding: 0.5rem;

              span {
                &.name {
                  color: #33cc66;
                  margin: 0 0.25rem;
                }
              }
            }

            .el-icon--replay {
              position: absolute;
              right: 0.5rem;
              top: 0.6rem;
            }

            .flag {
              .el-icon--replay {
                position: relative;
              }
            }
          }
        }
      }
    }
  }

  .comment-action {
    align-items: flex-end;

    .active {
      color: #ffffff;
      background: #33cc66;
    }

    .input-dom {
      // border: 1px solid #e5e5e5;
      .el-textarea__inner {
        min-height: 120px !important;
        max-height: 250px;
        line-height: 24px;
        width: 100%;
        // padding: 0.5rem;
        overflow-y: scroll;
      }
    }
  }
}
</style>
