import http from "@/utils/http";
import { Base64 } from "js-base64";
import store from "@/store"

function toXml(params) {
  const req = Object.keys(params)
    .map(k => `<${k}><![CDATA[${params[k]}]]></${k}>`)
    .join("");
  return `<?xml version = "1.0" encoding = "UTF-8"?>
<requests>
  <global>
    <resultType>json</resultType>
  </global>
  <request>
  ${req}
  </request>
</requests>`;
}
export function helome(params) {
  return http({
    //baseURL: "/",
    headers: {
      "Content-Type": "application/xml; charset=UTF-8"
    },
    method: "post",
    url: "helome/data.do",
    data: toXml(params)
  });
}
export function publicDo(params, url) {
  if (store.getters.userInfo.token) params.token = store.getters.userInfo.token;
  Object.keys(params).map(el => {
    let key = "";
    switch (el) {
      case "id":
        key = "id";
        break;
      case "providerId":
        key = "providerId";
        break;
      case "provider_id":
        key = "provider_id";
        break;
      case "userId":
        key = "userId";
        break;
      case "user_id":
        key = "user_id";
        break;
      case "enterprise_id":
        key = "enterprise_id";
        break;
      case "enterpriseId":
        key = "enterpriseId";
        break;
      case "groupId":
        key = "groupId";
        break;
      case "group_id":
        key = "group_id";
        break;
      case "gig_id":
        key = "gig_id";
        break;
    }
    if (key && params[key].startsWith("pai_")) {
      params[key] = Base64.decode(params[key].replace("pai_", ""));
    }
  });
  return http({
    headers: {
      "Content-Type": "application/xml; charset=UTF-8"
    },
    method: "post",
    url,
    withCredentials: false,
    data: toXml(params)
  });
}
