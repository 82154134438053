var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comments" }, [
    _vm.history.length
      ? _c(
          "ul",
          { staticClass: "list" },
          _vm._l(_vm.history, function(msg, index) {
            return _c("li", { key: index }, [
              msg.target.profile_photo
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: {
                      src: _vm.formartImageUrl(msg.target.profile_photo)
                    },
                    on: {
                      click: function($event) {
                        return _vm.routeJump({
                          name: "personalCenter",
                          params: { id: msg.target.reviewer_id }
                        })
                      }
                    }
                  })
                : _c("i", { staticClass: "avatar el-icon-user-solid" }),
              _c("dl", { staticClass: "right" }, [
                _c("dd", [
                  _c(
                    "span",
                    {
                      staticClass: "name",
                      on: {
                        click: function($event) {
                          return _vm.routeJump({
                            name: "personalCenter",
                            params: { id: msg.target.reviewer_id }
                          })
                        }
                      }
                    },
                    [_vm._v(_vm._s(msg.target.name) + "\n          ")]
                  ),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(_vm._s(msg.target.review_datetime))
                  ])
                ]),
                _c("dd", {
                  staticClass: "content",
                  domProps: { innerHTML: _vm._s(msg.target.content) }
                }),
                _c("dd", { staticClass: "replay" }, [
                  !msg.target.replay
                    ? _c("i", {
                        staticClass: "el-icon--replay btn",
                        on: {
                          click: function($event) {
                            return _vm.showReplayBox("history", index)
                          }
                        }
                      })
                    : _c("div", { staticClass: "replay-child-box" }, [
                        _c("span", { staticClass: "flag" }, [
                          _c("b", { staticClass: "el-icon--replay" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("replay") + ":" + msg.target.name
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("input", {
                          ref: "input" + index,
                          refInFor: true,
                          staticClass: "innnerInput",
                          attrs: { value: "" }
                        }),
                        _c("i", {
                          staticClass: "el-icon-check active",
                          on: {
                            click: function($event) {
                              return _vm.replayComment(
                                index,
                                msg.target.review_id
                              )
                            }
                          }
                        })
                      ])
                ]),
                _c(
                  "ul",
                  { staticClass: "child" },
                  _vm._l(msg.children, function(child, key) {
                    return _c("li", { key: key }, [
                      _c("p", [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(child.name))
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("replay")) +
                            "\n              "
                        ),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(child.parent_reply_name))
                        ]),
                        _c("span", {
                          domProps: { innerHTML: _vm._s(child.content) }
                        })
                      ]),
                      !child.replay
                        ? _c("i", {
                            staticClass: "el-icon--replay",
                            on: {
                              click: function($event) {
                                return _vm.showReplayBox("history", index, key)
                              }
                            }
                          })
                        : _c("div", { staticClass: "replay-child-box" }, [
                            _c("span", { staticClass: "flag" }, [
                              _c("b", { staticClass: "el-icon--replay" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("replay") + ":" + child.name
                                    ) +
                                    "\n                "
                                )
                              ])
                            ]),
                            _c("input", {
                              ref: "input" + index + "-" + key,
                              refInFor: true,
                              staticClass: "innnerInput"
                            }),
                            _c("i", {
                              staticClass: "el-icon-check active child",
                              on: {
                                click: function($event) {
                                  return _vm.replayComment(
                                    index,
                                    child.review_id,
                                    key
                                  )
                                }
                              }
                            })
                          ])
                    ])
                  }),
                  0
                )
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "comment-action" },
      [
        _c("el-input", {
          ref: "content",
          staticClass: "input-dom",
          attrs: { type: "textarea", id: "content" },
          on: { blur: _vm.blurHandler },
          model: {
            value: _vm.content,
            callback: function($$v) {
              _vm.content = $$v
            },
            expression: "content"
          }
        }),
        _c(
          "span",
          {
            class: { commentBtn: true, active: _vm.content !== "" },
            on: {
              click: function($event) {
                return _vm.CommentFunc()
              }
            }
          },
          [
            _c("i", {
              class: _vm.loading ? "el-icon-loading" : "el-icon-check"
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }